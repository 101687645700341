import check from "../../assets/images/icons/check.svg";

function PaymentSuccess() {
  return (
    <div className="text-center px-3 p-5">
      <img src={check} alt="success" className="my-4" style={{ maxWidth: 170 }} />
      <h2>Paymeny Successful</h2>
      <p>
        Click <a href="/">here</a> to go back to resturant menu.
      </p>
    </div>
  );
}

export default PaymentSuccess;
