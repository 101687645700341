import { useState } from "react";
import ResturantBanner from "./resturantBanner";
import RestaurantsMenuList from "../../components/RestaurantsMenuList";
import SideCartView from "../../components/SideCartView";
import { Container, Row, Col, Button } from "reactstrap";

import menuItemsData from "./mockData";

const Home = () => {
  const [menuItems, setMenuItems] = useState(menuItemsData);

  const category = [...new Set(menuItems.map((a) => a.category))];

  const handleCount = (id, type) => {
    const index = menuItems.findIndex((data) => data.id === id);

    const tempStore = [...menuItems];

    if (type === "add") {
      tempStore[index].cartCount = tempStore[index]?.cartCount ? tempStore[index].cartCount + 1 : 1;
    } else {
      tempStore[index].cartCount = tempStore[index]?.cartCount
        ? tempStore[index].cartCount > 0
          ? tempStore[index].cartCount - 1
          : 0
        : 0;
    }
    setMenuItems(tempStore);
  };

  const scrollToSection = (sectionId) => {
    const id = document.getElementById(sectionId);
    const menuList = document.querySelector(".menu-list-row");

    const resBanner = document.querySelector(".res-banner");
    const header = document.querySelector("#header");
    if (window.innerWidth > 767) {
      menuList.scrollTo({
        behavior: "smooth",
        top: id.offsetTop + resBanner.offsetHeight,
        left: 0,
      });
    } else {
      window.scrollTo({
        behavior: "smooth",
        top: id.offsetTop + header.offsetHeight + resBanner.offsetHeight,
        left: 0,
      });
    }
  };

  return (
    <>
      <Container fluid>
        <Row className="menu-list-row">
          <Col xs={12}>
            <ResturantBanner />
          </Col>
          <Col md={3} lg={2} className="pt-4 category-col">
            <div className="d-flex flex-wrap d-md-block category-menu">
              {category.map((data) => (
                <div>
                  <Button
                    color="link"
                    size="sm"
                    onClick={() => scrollToSection(data.replace(" ", "-"))}
                  >
                    {data}
                  </Button>
                </div>
              ))}
            </div>
          </Col>
          <Col md={5} lg={6} className="pt-4">
            <RestaurantsMenuList
              category={category}
              menuItems={menuItems}
              handleCount={handleCount}
            />
          </Col>
          <Col md={4} lg={4} className="cart-col pt-4">
            <SideCartView menuItems={menuItems} handleCount={handleCount} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
