import { Row, Col } from "reactstrap";
import resImage from "../../assets/images/resturant.webp";

const RatingSection = () => (
  <Row form>
    <Col>
      <p className="mb-1">
        <span className="icon-star mr-1" />
        4.4
      </p>
      <p className="mb-0 rs-values">1000+ Ratings</p>
    </Col>
    <Col>
      <p className="mb-1">-- mins</p>
      <p className="mb-0 rs-values">Delivery Time</p>
    </Col>
    <Col>
      <p className="mb-1">AED 450</p>
      <p className="mb-0 rs-values">Cost for two</p>
    </Col>
  </Row>
);
const Offer = () => (
  <div className="offer">
    <span className="offer-label">Offer</span>
    <p>
      <span className="icon-offer-filled" />
      20% off up to AED 50 | Use code AEDIT
    </p>
    <p className="mb-0">
      <span className="icon-offer-filled" />
      10% off up to AED 300 on orders above ₹600 | Use code PARTY
    </p>
  </div>
);

function ResturantBanner() {
  return (
    <div className="res-banner">
      <Row>
        <Col md={3} lg={3} className="mb-4 mb-md-0">
          <div style={{ maxWidth: 260 }}>
            <img className="img-fluid" src={resImage} alt="" />
          </div>
        </Col>
        <Col md={5} lg={6} className="mb-4 mb-md-0">
          <h2 className="title">Truffles</h2>
          <div className="dec">
            <p>American, Desserts, Continental, Italian</p>
            <p>St. Marks Road, Central Bangalore</p>
          </div>
          <RatingSection />
        </Col>
        <Col md={4} lg={3}>
          <Offer />
        </Col>
      </Row>
    </div>
  );
}

export default ResturantBanner;
