import { useState } from "react";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import emptyCartImage from "../../assets/images/empty-cart.webp";
import vegSymbol from "../../assets/images/icons/veg-symbol.svg";
import nonVegSymbol from "../../assets/images/icons/non-veg-symbol.svg";
import CentreLoader from "../loader/centerLoader";

const CartItem = (props) => {
  const { id, name, price, isVeg = true, cartCount, handleCount } = props;
  // const [totalItemCount, setTotalItemCount] = useState(0);
  // const handleCount = (type) => {
  //   if (type === "add") {
  //     setTotalItemCount((previous) => previous + 1);
  //   } else {
  //     setTotalItemCount((previous) => (previous !== 0 ? previous - 1 : previous));
  //   }
  // };
  return (
    <div className="cart-item">
      <div className="detail">
        <div>
          <img className="v-o-nv" src={isVeg ? vegSymbol : nonVegSymbol} />
        </div>
        <span className="name">{name}</span>
      </div>
      <div className="handler">
        <div className="controller">
          <button className="less" onClick={() => handleCount(id, "sub")}>
            -
          </button>
          <span className="count">{cartCount}</span>
          <button onClick={() => handleCount(id, "add")}>+</button>
        </div>
        <div>
          <span className="price">
            <b className="mr-1">AED</b>
            {price * cartCount}
          </span>
        </div>
      </div>
    </div>
  );
};

function SideCartView(props) {
  const { menuItems, handleCount } = props;
  const history = useHistory();
  const [isLoader, setIsLoader] = useState(false);
  const showingItems = [...menuItems].filter(({ cartCount = 0 }) => cartCount > 0);

  const checkout = () => {
    setIsLoader(true);
    setTimeout(() => history.push("/payment-success"), 3000);
  };
  const cartIsEmpty = () => {
    return (
      <div style={{ opacity: 0.5 }}>
        <h2 className="title">Cart Empty</h2>
        <img className="w-100" src={emptyCartImage} alt="empty cart" />
        <p>Good food is always cooking! Go ahead, order some yummy items from the menu.</p>
      </div>
    );
  };
  const cartHaveItem = () => {
    let totalpPrice = 0;

    for (let i = 0; i < showingItems.length; i++) {
      totalpPrice += showingItems[i].cartCount * showingItems[i].price;
    }

    return (
      <>
        {isLoader && <CentreLoader />}
        <div className="SideCartView">
          <h2 className="title">Cart</h2>
          <div className="content">
            {showingItems.map((data, index) => (
              <CartItem key={index} {...data} handleCount={handleCount} />
            ))}

            <div className="sub-p">
              <div className="d-flex justify-content-between">
                <p>Subtotal</p>
                <p>
                  <b className="pr-1">AED</b>
                  {totalpPrice}
                </p>
              </div>
              <div>
                <span className="extra-charges">Extra charges may apply</span>
              </div>
            </div>
            <Button color="success" onClick={checkout} className="mt-4 text-uppercase w-100 py-3">
              Checkout
            </Button>
          </div>
        </div>
      </>
    );
  };
  return showingItems.length > 0 ? cartHaveItem() : cartIsEmpty();
}

export default SideCartView;
