import Layout from "../components/layout";
import { Home, PaymentSuccess } from "../pages";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

export default () => (
  <Router>
    <Switch>
      <Layout>
        <Route exact path="/" component={Home} />
        <Route exact path="/payment-success" component={PaymentSuccess} />
      </Layout>
    </Switch>
  </Router>
);
