import { Modal } from "reactstrap";

function CenterLoader() {
  return (
    <Modal isOpen centered className="center-loader-modal">
      <div class="lds-default">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Modal>
  );
}

export default CenterLoader;
