import tempImage from "./temp.jpg";
export default [
  {
    id: 1,
    image: tempImage,
    name: "Nachos W Cheese",
    price: "172",
    dec: "Nacho chips served with salsa and cheese seperately",
    category: "Recommended",
  },
  {
    id: 2,
    image: tempImage,
    name: "Chicken Wings",
    price: "196",
    dec: "Fried chicken wings tossed in your choice of sauce",
    isBestseller: true,
    isVeg: false,
    category: "Recommended",
  },
  {
    id: 3,
    image: tempImage,
    name: "Crunchy Chicken Salad",
    price: "210",
    dec: "Crunchy fried chicken strips , lettuce & garlic mayo dressing all served seperately",
    isVeg: false,
    category: "Recommended",
  },
  {
    id: 4,
    name: "Fries",
    price: "110",
    dec: "French fries",
    isBestseller: true,
    category: "Staters",
  },

  {
    id: 5,
    name: "Corn Hi Corn",
    price: "181",
    dec: "Crumb fried baby corn sticks",
    isBestseller: true,
    category: "Staters",
  },
  {
    id: 6,
    name: "All American Nachos Veg",
    price: "239",
    dec: "Fried chicken wings tossed in your choice of sauce",
    isBestseller: true,
    category: "Wraps",
  },
  {
    id: 7,
    name: "French Toast",
    price: "115",
    dec: "Bread dipped in cinnamon flavoured egg served with maple syrup",
    isBestseller: true,
    isVeg: false,
    category: "Staters",
  },
  {
    id: 8,
    name: "Chicken Popcorn",
    price: "172",
    dec: "Spice dusted  crumb fried chicken bites served with dip",
    isBestseller: true,
    isVeg: false,
    category: "Staters",
  },
  {
    id: 9,
    name: "Cottage Cheese Steak",
    price: "258",
    dec:
      "BBQ flavoured nachos layered with bell peppers, mushroom, mexican beans, crispy iceberg lettuce with a double dressing of salsa & cheese sauce.",

    category: "Wraps",
  },
  {
    id: 10,
    name: "Cottage Cheese Steak",
    price: "258",
    dec:
      "Slices of cottage cheese sandwiched with cajun spice, spiced veggies, panco crumbed & served with a carribean sauce accompanied by sauteed vegetables & mashed potatoes",

    category: "Wraps",
  },
  {
    id: 11,
    name: "Egg Wrap",
    price: "120",
    dec: "Masala omlette wrapped in a flour tortilla",
    isVeg: false,
    category: "Wraps",
  },
  {
    id: 12,
    name: "Peri Peri Chicken Wrap",
    price: "120",
    dec: "Masala omlette wrapped in a flour tortilla",
    isVeg: false,
    category: "Wraps",
  },
];
