import { Container } from "reactstrap";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo/logo.png";

const Header = () => (
  <header id="header">
    <Container fluid>
      <Link to="/" className="logo">
        <img src={logo} alt="logo" />
      </Link>
    </Container>
  </header>
);

export default Header;
