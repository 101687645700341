import { Fragment, useState } from "react";
import { Button } from "reactstrap";
import vegSymbol from "../../assets/images/icons/veg-symbol.svg";
import nonVegSymbol from "../../assets/images/icons/non-veg-symbol.svg";

const MenuItemCard = (props) => {
  const {
    id,
    name,
    price,
    dec,
    isVeg = true,
    isBestseller = false,
    image = "",
    cartCount = 0,
    handleCount,
  } = props;
  // const [totalItemCount, setTotalItemCount] = useState(0);
  // const handleCount = (type) => {
  //   if (type === "add") {
  //     setTotalItemCount((previous) => previous + 1);
  //   } else {
  //     setTotalItemCount((previous) => (previous !== 0 ? previous - 1 : previous));
  //   }
  // };

  return (
    <div className="MenuItemCard">
      <div className="detail">
        <div className="type">
          <img className="v-o-nv" src={isVeg ? vegSymbol : nonVegSymbol} />
          {isBestseller && (
            <span>
              <i class="fas fa-star"></i> Bestseller
            </span>
          )}
        </div>
        <h3 className="name">{name}</h3>
        <p className="price">
          <b className="pr-1">AED</b>
          {price}
        </p>
        <p className="dec">{dec}</p>
      </div>
      <div className="items-to-cart">
        {image && (
          <div className="image-box">
            <img src={image} alt={name} />
          </div>
        )}
        <div className="handlerWrapper">
          {cartCount === 0 ? (
            <Button color="add" onClick={() => handleCount(id, "add")}>
              Add
            </Button>
          ) : (
            <div className="controller">
              <button className="less" onClick={() => handleCount(id, "sub")}>
                -
              </button>
              <span className="count">{cartCount}</span>
              <button onClick={() => handleCount(id, "add")}>+</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function RestaurantsMenuList(props) {
  const { menuItems, handleCount, category } = props;
  const unique = [...new Set(menuItems.map((a) => a.category))];

  return (
    <div className="RestaurantsMenuList">
      {category.map((uniqueData) => {
        return (
          <div className="mb-4">
            <h2 className="mb-4" id={uniqueData.replace(" ", "-")}>
              {uniqueData}
            </h2>
            {menuItems
              .filter(({ category }) => category === uniqueData)
              .map((data, index) => (
                <Fragment key={index}>
                  {index !== 0 && <hr />}
                  <MenuItemCard {...data} handleCount={handleCount} />
                </Fragment>
              ))}
          </div>
        );
      })}

      {/* {menuItems.map((data, index) => (
        <Fragment key={index}>
          {index !== 0 && <hr />}
          <MenuItemCard {...data} handleCount={handleCount} />
        </Fragment>
      ))} */}
    </div>
  );
}

export default RestaurantsMenuList;
